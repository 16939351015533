var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "login d-flex justify-center" },
    [
      _c(
        "v-main",
        { staticClass: "d-flex" },
        [
          _c(
            "v-layout",
            {
              attrs: {
                fluid: "",
                flex: "",
                "flex-wrap": "",
                "justify-center": "",
              },
            },
            [
              _c(
                "v-flex",
                {
                  attrs: {
                    "d-flex": "",
                    xs12: "",
                    sm5: "",
                    "align-center": "",
                    "elevation-0": "",
                  },
                },
                [
                  _c(
                    "v-card",
                    {
                      staticClass: "pa-16",
                      attrs: { flat: "", width: "100%" },
                    },
                    [
                      _c("div", { staticClass: "d-flex mb-16 pb-3" }, [
                        _c(
                          "a",
                          {
                            staticClass: "text-decoration-none black--text",
                            attrs: { href: "/login" },
                          },
                          [
                            _c("v-icon", { attrs: { small: "", left: "" } }, [
                              _vm._v(" arrow_back "),
                            ]),
                            _vm._v(" Back"),
                          ],
                          1
                        ),
                      ]),
                      _c("v-layout", { staticClass: "flex-column" }, [
                        _c(
                          "h4",
                          { staticClass: "display-1 font-weight-medium pb-2" },
                          [_vm._v("Verify E-mail")]
                        ),
                      ]),
                      _c(
                        "v-card-text",
                        { staticClass: "py-4 px-0" },
                        [
                          true
                            ? _c("p", [_c("strong", [_vm._v(_vm._s(_vm.msg))])])
                            : _vm._e(),
                          _c(
                            "v-form",
                            {
                              ref: "form",
                              attrs: {
                                action: _vm.action,
                                method: "POST",
                                role: "form",
                              },
                              model: {
                                value: _vm.valid,
                                callback: function ($$v) {
                                  _vm.valid = $$v
                                },
                                expression: "valid",
                              },
                            },
                            [
                              _c("input", {
                                attrs: { type: "hidden", name: "csrf_token" },
                                domProps: { value: _vm.csrf_token },
                              }),
                              _c("v-text-field", {
                                attrs: {
                                  label: "Email Address",
                                  placeholder: "Enter your e-mail address",
                                  name: "email",
                                  required: "",
                                  outlined: "",
                                  dense: "",
                                  rules: _vm.emailRules,
                                },
                                model: {
                                  value: _vm.email,
                                  callback: function ($$v) {
                                    _vm.email =
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                  },
                                  expression: "email",
                                },
                              }),
                              _c(
                                "v-dialog",
                                {
                                  attrs: {
                                    persistent: "",
                                    "max-width": "600px",
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "activator",
                                      fn: function (ref) {
                                        var on = ref.on
                                        var attrs = ref.attrs
                                        return [
                                          _c(
                                            "v-btn",
                                            _vm._g(
                                              _vm._b(
                                                {
                                                  staticClass:
                                                    "primary white--text mb-7",
                                                  attrs: {
                                                    type: "submit",
                                                    block: "",
                                                    depressed: "",
                                                    large: "",
                                                    disabled: !_vm.valid,
                                                  },
                                                },
                                                "v-btn",
                                                attrs,
                                                false
                                              ),
                                              _vm.off
                                            ),
                                            [_vm._v("Submit")]
                                          ),
                                        ]
                                      },
                                    },
                                  ]),
                                  model: {
                                    value: _vm.dialog,
                                    callback: function ($$v) {
                                      _vm.dialog = $$v
                                    },
                                    expression: "dialog",
                                  },
                                },
                                [
                                  _c(
                                    "v-card",
                                    { staticClass: "pa-16" },
                                    [
                                      _c(
                                        "v-card-text",
                                        [
                                          _c(
                                            "v-container",
                                            { staticClass: "text-center" },
                                            [
                                              _c(
                                                "v-row",
                                                {
                                                  staticClass: "mb-6",
                                                  attrs: { justify: "center" },
                                                },
                                                [
                                                  _c("v-img", {
                                                    attrs: {
                                                      "max-height": "173",
                                                      "max-width": "173",
                                                      src: require("../assets/images/success-check.svg"),
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "h2",
                                                {
                                                  staticClass:
                                                    "pb-4 font-weight-medium black--text",
                                                },
                                                [
                                                  _vm._v(
                                                    "Please Check Your Email "
                                                  ),
                                                ]
                                              ),
                                              _c("p", [
                                                _vm._v(
                                                  "To set a new password please check your email"
                                                ),
                                              ]),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-card-actions",
                                        { staticClass: "justify-center mt-7" },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                width: "164",
                                                color: "primary",
                                                depressed: "",
                                                large: "",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  _vm.dialog = false
                                                },
                                              },
                                            },
                                            [_vm._v(" Close ")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "a",
                            {
                              staticClass: "text-center mb-4",
                              attrs: { href: "/login" },
                            },
                            [_vm._v("Login Instead")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-flex",
                {
                  attrs: {
                    "d-flex": "",
                    xs12: "",
                    sm7: "",
                    "align-center": "",
                    "elevation-0": "",
                    blue: "",
                    "accent-4": "",
                  },
                },
                [
                  _c(
                    "v-card",
                    { staticClass: "blue accent-4 pa-16", attrs: { flat: "" } },
                    [
                      _c("div", { staticClass: "pt-4 white--text" }, [
                        _c(
                          "h2",
                          { staticClass: "display-1 font-weight-medium pb-6" },
                          [_vm._v("The Kochava Marketers Operating System™")]
                        ),
                        _c("p", [
                          _vm._v(
                            "The Marketers Operating System (m/OS) seamlessly integrates omni-channel marketing solutions for advertisers and publishers in one operational platform."
                          ),
                        ]),
                      ]),
                      _c(
                        "v-card-text",
                        { staticClass: "pt-4 px-0 white--text" },
                        [
                          _c("v-img", {
                            attrs: {
                              src: require("../assets/images/login-img.svg"),
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }